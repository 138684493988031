.footer {
  position: relative;
  background: #092845;
  color: #ffffff;
  padding: 80px 0 20px;
  overflow: hidden;
  margin-top: 50px;
}

/* Wave Effect */
.footer__wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.footer__wave svg {
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
}

/* .wave-fill {
  fill: #ffffff;
} */

/* Container */
.footer__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
}

/* Sections */
.footer__section {
  margin-bottom: 30px;
}

.footer__brand {
  max-width: 300px;
}

.footer__logo {
  max-width: 150px;
  margin-bottom: 20px;
}

.footer__description {
  color: #ffffff;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: "Lato", sans-serif;

}

/* Headings */
.footer__heading {
  color: #6889ff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 10px;
  font-family: "Lato", sans-serif;

}

.footer__heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #6889ff;
  transition: width 0.3s ease;
}

.footer__heading:hover::after {
  width: 60px;
}

/* Links */
.footer__links {
  list-style: none;
  padding: 0;
}

.footer__links li {
  margin-bottom: 12px;
}

.footer__links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  font-family: "Lato", sans-serif;

}

.link__line {
  display: inline-block;
  width: 0;
  height: 1px;
  background-color: #6889ff;
  margin-right: 10px;
  transition: width 0.3s ease;
}

.footer__links a:hover {
  color: #6889ff;
}

.footer__links a:hover .link__line {
  width: 20px;
}

/* Contact */
.footer__contact {
  list-style: none;
  padding: 0;
  font-family: "Lato", sans-serif;

}

.footer__contact-item {
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.contact__icon {
  color: #6889ff;
  margin-right: 12px;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.footer__contact-item:hover .contact__icon {
  transform: scale(1.1);
}

/* Social Links */
.footer__social {
  display: flex;
  gap: 15px;
}

.social__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(104, 137, 255, 0.1);
  border-radius: 8px;
  color: #6889ff;
  transition: all 0.3s ease;
}

.social__link:hover {
  background-color: #6889ff;
  color: white;
  transform: translateY(-3px);
}

/* Copyright */
.footer__bottom {
  text-align: center;
  padding-top: 20px;
  margin-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 0.9rem;
  font-family: "Lato", sans-serif;

}

/* Scroll to Top Button */
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #6889ff;
  color: white;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  box-shadow: 0 4px 12px rgba(104, 137, 255, 0.25);
  transition: all 0.3s ease;
  z-index: 999;
}

.scroll-to-top:hover {
  transform: translateY(-3px);
  background-color: #5272ff;
  box-shadow: 0 6px 16px rgba(104, 137, 255, 0.35);
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    padding: 60px 0 20px;
  }

  .footer__container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }

  .footer__section {
    margin-bottom: 20px;
  }

  .footer__brand {
    max-width: 100%;
  }

  .footer__social {
    justify-content: center;
  }

  .scroll-to-top {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer__section {
  animation: fadeIn 0.6s ease-out forwards;
}

.footer__section:nth-child(1) {
  animation-delay: 0.1s;
}
.footer__section:nth-child(2) {
  animation-delay: 0.2s;
}
.footer__section:nth-child(3) {
  animation-delay: 0.3s;
}
.footer__section:nth-child(4) {
  animation-delay: 0.4s;
}

