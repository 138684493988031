.header-Selmore {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: all 0.3s ease;
  z-index: 999;
}

.header-Selmore.scrolled {
  background: #092845;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 90%;
  padding: 0 20px;
}

/* Logo */
.logo-main-selmore img {
  width: 100px;
  transition: transform 0.3s ease;
}

.logo-main-selmore:hover img {
  transform: scale(1.05);
}

/* Navigation links */
.nav-links {
  display: flex;
  gap: 30px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s, transform 0.3s;
  font-family: "Lato", sans-serif;
  position: relative;
}

.nav-links a:hover {
  color: #6889FF;
  transform: translateY(-2px);
}

.nav-links a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #6889FF;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-links a:hover::after {
  transform: scaleX(1);
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(13, 15, 49, 0.9);
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: rgba(104, 137, 255, 0.2);
}

/* Book Now button */
.book-nowbtn {
  background-color: transparent;
  color: white;
  border: 2px solid #6889FF;
  padding: 12px 35px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  font-family: "Lato", sans-serif;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.book-nowbtn:hover {
  background-color: #6889FF;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(104, 137, 255, 0.3);
}

/* Mobile menu toggle */
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 1024px) {
  .header-Selmore {
    padding: 10px;
  }

  .mobile-menu-toggle {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 70px;
    left: -110%;
    width: 100%;
    height: calc(100vh - 70px);
    flex-direction: column;
    background-color: rgba(13, 15, 49, 0.95);
    padding: 20px;
    transition: left 0.3s ease;
    overflow-y: auto;
  }

  .nav-links.open {
    left: 0;
  }

  .nav-links a {
    padding: 15px 0;
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .dropdown-content {
    position: static;
    background-color: transparent;
    box-shadow: none;
    display: none;
  }

  .dropdown.open .dropdown-content {
    display: block;
  }

  .dropdown-content a {
    padding-left: 20px;
  }

  .book-now-link {
    display: none;
  }

  .book-nowbtn {
    width: 100%;
    margin-top: 20px;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.header-Selmore {
  animation: fadeIn 0.5s ease;
}

.nav-links a {
  animation: fadeIn 0.5s ease backwards;
}

.nav-links a:nth-child(1) { animation-delay: 0.1s; }
.nav-links a:nth-child(2) { animation-delay: 0.2s; }
.nav-links a:nth-child(3) { animation-delay: 0.3s; }
.nav-links a:nth-child(4) { animation-delay: 0.4s; }
.nav-links a:nth-child(5) { animation-delay: 0.5s; }
.nav-links a:nth-child(6) { animation-delay: 0.6s; }