.price-range-slider {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .slider-container {
    position: relative;
    width: 100%;
    height: 40px;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    background: transparent;
    pointer-events: none;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    border: 2px solid #3b82f6;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -8px;
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    border: 2px solid #3b82f6;
    cursor: pointer;
    pointer-events: auto;
  }
  
  .slider-0 {
    z-index: 1;
  }
  
  .slider-1 {
    z-index: 2;
  }
  
  .slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
  }
  
  .slider::-moz-range-track {
    width: 100%;
    height: 4px;
    background: #e5e7eb;
    border-radius: 2px;
  }
  
  .range-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
  }
  
  .input-group label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #ffffff;
  }
  
  .input-group input {
    width: 80px;
    padding: 5px;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .separator {
    margin: 0 10px;
    color: #4b5563;
  }
  
  .ok-button {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .ok-button:hover {
    background-color: #2563eb;
  }
  
  