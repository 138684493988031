.toast {
    position: fixed;
    top: 20px;
    right: -300px;
    min-width: 300px;
    padding: 20px 16px;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    z-index: 9999;
    transition: right 0.5s ease-in-out;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .toast.show {
    right: 20px;
  }
  
  .toast.error {
    background-color: #ff4d4f;
  }
  
  .toast.success {
    background-color: #52c41a;
  }
  
  @media (max-width: 768px) {
    .toast {
      width: calc(100% - 40px);
      min-width: unset;
    }
  }
  
  