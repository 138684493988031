/* Add these new styles at the top */
.portfolio-loading,
.portfolio-error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.portfolio-error {
  color: #dc2626;
}

.portfolio-section {
  background: linear-gradient(to bottom, #ffffff, #f5f5f5);
  padding: 50px 20px;
  color: #000000;
  text-align: center;
  font-family: "Arial", sans-serif;
  position: relative;
  z-index: 1;
  isolation: isolate;
}

.portfolio-header {
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-subtitle {
  font-size: 50px;
  font-weight: 500;
  font-family: "Helvetica Neue", sans-serif;
  padding: 0 0 10px 0;
  margin: 0;
  color: #0260a6;
}

.portfolio-title {
  font-size: 36px;
  font-family: "Helvetica Neue", sans-serif;
  color: #000000;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-weight: 500;
}

.portfolio-description {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 40px;
}

.portfolio-container {
  perspective: 1000px;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  transform-style: preserve-3d;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  position: relative;
  will-change: transform, opacity;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.portfolio-grid.slide-right {
  animation: slideRight 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.portfolio-grid.slide-left {
  animation: slideLeft 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.portfolio-item {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 350px;
  background: #fff;
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.portfolio-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.portfolio-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(2, 96, 166, 0.7);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.portfolio-item:hover .overlay {
  opacity: 1;
}

.plus-sign {
  font-size: 40px;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.item-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: rgba(255, 255, 255, 0.95);
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.item-details h3 {
  margin: 0 0 5px 0;
  color: #0260a6;
  font-size: 18px;
}

.item-details p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.portfolio-item:hover .item-details {
  transform: translateY(0);
}

/* Carousel Dots */
.carousel-dots {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 30px;
}

.carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: rgba(2, 96, 166, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
}

.carousel-dot.active {
  background-color: #0260a6;
  transform: scale(1.2);
}

.carousel-dot:hover {
  background-color: #0260a6;
  transform: scale(1.1);
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 900px;
  width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
  z-index: 9999;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 1px;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  z-index: 2;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.close-button:hover {
  background-color: #f20707;
  color: #ffffff;
  transform: scale(1.1);
}

.popup-image-slider {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 30px;
}

.popup-image {
  position: relative;
  width: 90%;
  height: 400px;
  overflow: hidden;
  border-radius: 8px;
  margin: auto;
}

.popup-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.image-counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
}

/* Popup Slider Dots */
.popup-slider-dots {
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
}

.popup-slider-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: none;
  background-color: rgba(2, 96, 166, 0.3);
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0;
}

.popup-slider-dot.active {
  background-color: #0260a6;
  transform: scale(1.2);
}

.popup-slider-dot:hover {
  background-color: #0260a6;
  transform: scale(1.1);
}

.popup-details {
  padding: 20px 0;
}

.popup-details h2 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 24px;
}

.category {
  display: inline-block;
  padding: 5px 15px;
  background: #0260a6;
  color: white;
  border-radius: 20px;
  font-size: 14px;
  margin-bottom: 15px;
}

.description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 16px;
}

.project-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.info-item {
  color: #333;
  font-size: 14px;
}

/* Animations */
@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(30px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateX(0) translateZ(0);
  }
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(-30px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateX(0) translateZ(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .portfolio-grid {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }

  .portfolio-description {
    font-size: 16px;
  }

  .portfolio-container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .portfolio-section {
    padding: 30px 15px;
  }

  .portfolio-title {
    font-size: 28px;
  }

  .portfolio-subtitle {
    font-size: 20px;
  }

  .popup-content {
    padding: 20px;
    width: 95%;
  }

  .popup-image {
    height: 300px;
  }

  .portfolio-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .popup-image {
    height: 250px;
  }

  .portfolio-description {
    font-size: 14px;
  }

  .carousel-dot,
  .popup-slider-dot {
    width: 8px;
    height: 8px;
  }
}

