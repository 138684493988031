.accordion-main-import {
  width: 100%;
  height: auto;
  /* background-image: url(../images/doted.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  /* padding: 190px 0px; */
  /* margin-top: -310px; */
  z-index: 9999;

}
.accordion-main-import img {
  width: 70%;
  position: absolute;
  left: 0;
  top: -100%;
  z-index: -1;
}
.accordion-main-text {
  width: 80%;
  margin: auto;
}

.accordion-main-text h3 {
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  font-family: "Raleway", sans-serif;
  margin-bottom: 10px;
  padding-top: 10px;
  padding: 20px 0px;
}

.service-detail-main-text {
  width: 80%;
  margin: auto;
  margin-top: 150px;
}

.service-detail-main-text h3 {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
  font-family: "Raleway", sans-serif;
  margin-bottom: 10px;
  padding-top: 10px;
  padding: 20px 0px 40px 0px;
  text-align: center;
}

.service-detail {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  padding-bottom: 30px;
}

.service-detail img {
  width: 50%;
}

.service-detail-inner {
  width: 50%;
}

.service-detail-inner p {
  color: rgb(255, 255, 255);
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0;
  padding-top: 20px;
  font-size: 16px;
}

.service-detail-img {
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.dotedline-accordion-detail {
  width: 100%;
  height: auto;
  /* background-image: url(../images/doted.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 190px 0px;
  margin-top: -100px;
  z-index: -1;
  position: absolute;
  top: 0;
}

.dotedline-accordion-detail img {
  /* width: 100%; */
  position: absolute;
  top: 0;
  height: fit-content;
}

@media (max-width: 768px) {
  .service-detail-main-text h3{
    font-size: 30px;
  }
  .service-detail {
    flex-direction: column-reverse;
    width: 90%;
  }

  .service-detail-inner {
    width: 100%;
  }

  .service-detail-img {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .accordion-main-text h3 {
    padding: 90px 0px;
  }

  .accordion-main-text {
    width: 90%;
  }

  .accordion-main-import {
    margin-bottom: 20px;
  }

  .service-detail img {
    width: 100%;
  }
  .dotedline-accordion-detail img {
    width: 100%;
   
}
}