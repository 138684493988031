.explore-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 90%;
  margin: 140px auto auto auto;
}

.explore-layout {
  display: flex;
  gap: 1.5rem;
  height: 100%;
}

/* Sidebar Styles */
.sidebar {
  width: 25%;
  flex-shrink: 0;
  padding: 10px 20px;
}

.filter-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  font-family: "Lato", sans-serif;
  font-size: 20px;
}

.filter-header svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #007bc9;
}

.sidebar-scroll {
  height: 82%;
  overflow-y: auto;
}

/* Sidebar Scrollbar Styles */
.sidebar-scroll::-webkit-scrollbar {
  width: 6px;
}

.sidebar-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  background-color: #007bc9;
  border-radius: 3px;
}

/* For Firefox */
.sidebar-scroll {
  scrollbar-width: thin;
  scrollbar-color: #007bc9 transparent;
}

/* When hovering over the scrollbar */
.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #007bc9;
}

.filter-sections {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 10px 0px;
}

.filter-section h3 {
  font-weight: 500;
  margin-bottom: 0.75rem;
  font-family: "Lato", sans-serif;
  padding: 10px 0px;
}

.for-border-b {
  border-bottom: 1px solid white;
  width: 80%;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-family: "Helvetica Neue", sans-serif;
  color: rgba(255, 255, 255, 0.6);
}

/* Updated Checkbox Styles */
.checkbox-label input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
  background-color: transparent;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: #007bc9 !important;
  border-color: #007bc9 !important;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: "✓";
  position: absolute;
  color: white;
  font-size: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.show-more-filte {
  font-size: 0.875rem;
  color: #60a5fa;
  background: none;
  border: none;
  cursor: pointer;
  padding-bottom: 0;
  display: flex;
  align-items: flex-start;
  color: rgba(255, 255, 255, 0.6);
}

/* Main Content Styles */
.main-content {
  flex: 1;
  height: fit-content;
}

/* Search Container Styles */
.search-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.search-input-wrapper {
  position: relative;
  flex: 1;
}

.search-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.search-input {
  width: 91%;
  height: 49px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 2px;
  padding: 0 1rem 0 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
}

.search-input:focus {
  outline: none;
  border-color: #007bc9;
}

.search-button {
  padding: 0 1rem;
  height: 50px;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
  width: 20%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  font-family: "Lato", sans-serif;
}

.search-button:hover {
  background-color: #3b82f6;
  border: 1px solid rgba(255, 255, 255, 0.42);
}

/* Active Filters Styles */
.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;
}

.filter-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  background-color: rgba(0, 123, 201, 0.1);
  border: 1px solid #007bc9;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Lato", sans-serif;
}

.remove-filter {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  transition: color 0.2s;
}

.remove-filter:hover {
  color: #007bc9;
}

.remove-filter svg {
  width: 16px;
  height: 16px;
}

/* Billboard Grid Styles */
.billboard-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.billboard-card {
  flex: 1 1 calc(33.333% - 1rem);
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}

.billboard-image {
  width: 100%;
  height: 338px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.billboard-image:hover {
  transform: scale(1.3);
}

.billboard-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.75rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
}

.billboard-info h3 {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  font-family: "Lato", sans-serif;
  color: white;
}

.billboard-info p {
  font-size: 0.75rem;
  color: #d1d5db;
  font-family: "Helvetica Neue", sans-serif;
}

/* Pagination Styles */
.pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  align-items: center;
}

.pagination svg {
  color: #037ad7;
  font-size: 10px;
  font-weight: 500;
}

.pagination button {
  min-width: 40px;
  height: 40px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination button:hover {
  background-color: #007bc9;
  border-color: #007bc9;
}

.pagination button.active {
  background-color: #007bc9;
  border-color: #007bc9;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Error Message Styles */
.error-message {
  color: #ef4444;
  text-align: center;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #ef4444;
  border-radius: 4px;
}

/* Mobile Filter Toggle Button */
.mobile-filter-toggle {
  display: none;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.filter-align {
  display: flex;
  align-items: center;
}

.mobile-filter-toggle svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #007bc9;
}

.filter-cancel-button {
  display: none;
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.6);
}

/* Price Range Slider Styles */
.price-range-slider {
  padding: 1rem 0;
}

.price-range-slider .MuiSlider-root {
  color: #007bc9;
}

.price-range-slider .MuiSlider-thumb {
  background-color: white;
  border: 2px solid #007bc9;
}

.price-range-slider .MuiSlider-track {
  background-color: #007bc9;
}

.price-range-slider .MuiSlider-rail {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Tablet Responsive Media Query */
@media (min-width: 769px) and (max-width: 1024px) {
  .explore-container {
    width: 95%;
    padding: 30px;
  }

  .explore-layout {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    margin-bottom: -3rem;
  }

  .sidebar-scroll {
    height: auto;
    max-height: 300px;
  }

  .main-content {
    width: 100%;
  }

  .search-container {
    flex-direction: row;
    align-items: center;
  }

  .search-input {
    width: 70%;
  }

  .search-button {
    width: 25%;
    margin-top: 0;
  }

  .billboard-grid {
    gap: 15px;
  }

  .billboard-card {
    flex: 1 1 calc(50% - 15px);
  }

  .billboard-image {
    height: 300px;
  }

  .mobile-filter-toggle {
    display: none;
  }
}

/* Mobile Responsive Media Query */
@media (max-width: 768px) {
  .filter-cancel-button {
    display: block;
  }

  .explore-layout {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: fixed;
    top: 0;
    left: -110%;
    height: 100vh;
    background-color: #03001424;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }

  .sidebar.open {
    left: 0;
    top: 90px;
    background-color: black;
  }

  .sidebar-scroll {
    height: calc(100vh - 60px);
    padding-bottom: 60px;
  }

  .mobile-filter-toggle {
    display: flex;
    width: 28%;
    position: absolute;
    right: 17px;
    padding: 12px;
  }

  .search-container {
    flex-direction: column;
  }

  .search-input {
    width: 80%;
  }

  .search-input-wrapper {
    width: fit-content;
  }

  .search-button {
    width: 100%;
    margin-top: 1rem;
    display: none;
  }

  .billboard-card {
    flex: 1 1 100%;
  }

  .filter-sections {
    background-color: black;
  }

  .explore-container {
    width: 90%;
    margin: 100px auto auto auto;
    padding: 0;
  }

  .billboard-image {
    width: 100%;
    height: 375px;
  }

  .filter-header {
    justify-content: space-between;
    width: 90%;
  }

  .pagination {
    padding: 20px 0px;
  }

  .active-filters {
    margin: 8px 0;
  }

  .filter-tag {
    font-size: 0.875rem;
  }
}

/* Update error message styling */
.error-message {
  color: #ef4444;
  text-align: center;
  padding: 1rem;
  margin: 1rem 0;
  background-color: rgba(239, 68, 68, 0.1);
  border: 1px solid #ef4444;
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.error-message::before {
  content: "⚠️";
  font-size: 1.2em;
}
