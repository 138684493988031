.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
  }
  
  .loader-container {
    position: relative;
    width: 120px;
    height: 120px;
    animation: container-scale 2s ease-in-out infinite;
  }
  
  .loader-ring {
    position: absolute;
    border-radius: 50%;
    border: 3px solid transparent;
  }
  
  .loader-ring.outer {
    width: 100%;
    height: 100%;
    border-top: 3px solid #0bd6e8;
  
    border-right: 3px solid #ffffff;
    animation: spin 2s linear infinite;
    filter: drop-shadow(0 0 8px rgba(0, 234, 255, 0.3));
  }
  /* // background: linear-gradient(to right, #0bd6e8, #0a3d6f); */
  
  .loader-ring.inner {
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border-bottom: 3px solid #0bd6e8;
    border-left: 3px solid #ffffff;
    animation: spin 2s linear infinite reverse;
  }
  
  .loader-circle {
    position: absolute;
    width: 70%;
    height: 70%;
    top: 15%;
    left: 15%;
    background: radial-gradient(
      circle,
      rgba(0, 234, 255, 0.1) 0%,
      rgba(0, 234, 255, 0.05) 65%,
      transparent 100%
    );
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 2s ease-in-out infinite;
  }
  
  .loader-logo {
    width: 85%;
    height: 85%;
    object-fit: contain;
    animation: float 3s ease-in-out infinite;
    filter: drop-shadow(0 0 8px rgba(0, 234, 255, 0.3));
  }
  
  .loader-dots {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: spin 12s linear infinite;
  }
  
  .loader-dot {
    position: absolute;
    width: 4px;
    height: 4px;
    background: linear-gradient(to right, #3c8ce7, #0bd6e8) !important;
    border-radius: 50%;
    top: -2px;
    left: calc(50% - 2px);
    transform-origin: center 60px;
    transform: rotate(calc(var(--dot-index) * 45deg)) translateY(0);
    animation: dot-fade 2s ease-in-out infinite;
    animation-delay: calc(var(--dot-index) * 0.25s);
  }
  
  .loader-progress {
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    width: 50%;
    height: 4px;
    background: transparent;
    transform-origin: left;
    animation: progress-rotate 2s linear infinite;
  }
  
  .loader-progress-dot {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(to right, #3c8ce7, #0bd6e8) !important;
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px #0bd6e8;
  }
  .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background: linear-gradient(to right, #3c8ce7, #0bd6e8) !important;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
  }
  
  @keyframes float {
    0%,
    100% {
      transform: translateY(0) scale(1);
    }
    50% {
      transform: translateY(-5px) scale(1.02);
    }
  }
  
  @keyframes dot-fade {
    0%,
    100% {
      opacity: 0;
      transform: rotate(calc(var(--dot-index) * 45deg)) translateY(0);
    }
    50% {
      opacity: 1;
      transform: rotate(calc(var(--dot-index) * 45deg)) translateY(-10px);
    }
  }
  
  @keyframes progress-rotate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
  
  @keyframes container-scale {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.02);
    }
  }
  
  /* Add glass effect on hover */
  .loader-container:hover .loader-ring.outer {
    border-top-color: #0bd6e8;
    border-right-color: #0a3d6f;
    transition: all 0.3s ease;
  }
  
  /* Add responsive scaling */
  @media (max-width: 768px) {
    .loader-container {
      transform: scale(0.8);
    }
  }
  